<style scoped>
.text-field-date-doc{
   padding: 0;
}
.v-application .v-btn.v-btn--disabled .white--text {
  color: gray !important;
}
</style>
<template>
    <div>
      <v-main class="white mt-1">
        <v-progress-linear
          :active="loadingProject"
          :indeterminate="loadingProject"
          absolute
          bottom
          color="primary accent-4"
        ></v-progress-linear>
        <v-progress-linear
          :active="savingProject"
          :indeterminate="savingProject"
          absolute
          bottom
          color="primary accent-4"
        ></v-progress-linear>
        <v-scale-transition>
          <v-form class="form-project" v-model="isFormValid" lazy-validation v-if="!loadingProject" ref="formEdit">
            <div class="form-container">
              <v-container>
              <div class="p-absolute alert-container right-5 w-full">
                <div class="alert-item alert--success" v-if="saveSuccessAlert">
                  <img src="check-circle.png" alt="Guardar"/>
                  <div class="alert-item--text ml-2">
                    <h6 class="text-body-1 font-weight-bold">Cambios Guardados</h6>
                    <p>Las ediciones han sido guardadas con éxito.</p>
                  </div>
                  <v-btn min-width="10" class="close-alert" icon color="black">
                    <v-icon color="#000000" class="" @click="saveSuccessAlert = false">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
                <div class="alert-item alert--error" v-if="saveErrorAlert">
                  <img src="error-circle.png" alt="Guardar"/>
                  <div class="alert-item--text ml-2">
                    <h6 class="text-body-1 font-weight-bold">Error al guardar cambios.</h6>
                    <p>Las ediciones no han sido guardadas con éxito.</p>
                  </div>
                  <v-btn min-width="10" class="close-alert" icon color="black">
                    <v-icon color="#000000" class="" @click="saveErrorAlert = false">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <v-row class="pb-5">
                <v-col>
                  <div class="header-actions d-flex justify-space-between mb-3">
                    <h2 class="text-blue-sky">Edición de Expediente</h2>
                  </div>
                  <div class="action-wrapper d-flex flex-column flex-sm-row">
                    <div>
                      <v-btn text class="label-input ml-4 ml-sm-2 pl-0 text-blue-sky" :disabled="!(currentProject.document && currentProject.document.url)"
                          target="_blank" :href="(currentProject.document && currentProject.document.url) ?
                      (isOfficeDoc(currentProject.document.text) ? `https://view.officeapps.live.com/op/embed.aspx?src=${currentProject.document.url}` : currentProject.document.url) : ''">
                        <v-icon left>
                          mdi-file-document-multiple-outline
                        </v-icon>
                        <span class="text-decoration-underline">Ver Documento Vigente</span>
                      </v-btn>
                      <a v-if="currentProject.document && currentProject.document.url" v-bind:href="currentProject.document.url" download>
                        <v-icon color="#1867C0" >
                          mdi-download
                        </v-icon>
                      </a>
                      <v-dialog v-if="isAdmin" v-model="dDocument1" width="700">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn min-width="20" class="pa-0" icon color="black" :disabled="isArchivado || isProjectLeyRepublica"
                            v-bind="attrs"
                            v-on="on">
                            <v-icon color="#1867C0" :loading="isSelecting">
                                  mdi-cloud-upload
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="p-relative">
                            <h6 class="text-h6 mt-8">Subir Documento</h6>
                            <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4"
                            icon color="black">
                              <v-icon color="#1867C0" class="" @click="dDocument1 = false">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-menu
                                    ref="menuDocDate1"
                                    v-model="menuDocDate1"
                                    :return-value.sync="menuDocDate1"
                                    transition="scale-transition"
                                    min-width="auto"
                                    offset-y
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                          class="text-field-date-doc"
                                          v-model="docDate"
                                          prepend-icon="mdi-calendar"
                                          flat
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                      </v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="docDate"
                                      no-title
                                      scrollable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="menuDocDate1 = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menuDocDate1.save(date)"
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-menu>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-btn :disabled="isArchivado || isProjectLeyRepublica"
                                  class="label-input w-full"
                                  color="#1867C0"
                                    :loading="isSelecting"
                                    @click="handleFileImport({
                                      path: 'currentProject'
                                    })">
                                    <v-icon left class="white--text">
                                      mdi-upload-outline
                                    </v-icon>
                                    <span class="white--text font-weight-bold d-none d-sm-flex">Selecionar Documento</span>
                                  </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions class="d-flex justify-end pb-8 px-6">
                            <v-btn color="#1867C0" outlined width="90" class="label-input"
                                  @click="dDocument1 = false">
                              Salir
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                    <div>
                      <v-dialog v-model="dresumen"
                                width="1200">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="label-input ml-2 text-blue-sky" v-bind="attrs" v-on="on">
                            <v-icon left>
                              mdi-pencil-outline
                            </v-icon>
                            <span class="text-decoration-underline">Resumen</span>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5 text-blue-sky lighten-2">
                            <h6 class="text-h6 mt-8 mb-4">Resumen</h6>
                            <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4" icon color="black">
                              <v-icon color="#1867C0" class="" @click="dresumen = false">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <v-tiptap v-model="currentProject.resumen" :view="isArchivado || isProjectLeyRepublica" />
                          </v-card-text>
                          <v-card-actions class="d-flex justify-end pb-8 px-6">
                            <v-btn color="#1867C0" outlined width="90" class="label-input"
                                  @click="dresumen = false">
                              Cerrar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dbitacora"
                                width="1200">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="label-input ml-2 text-blue-sky"
                                v-bind="attrs"
                                v-on="on">
                            <v-icon left>
                              mdi-comment-outline
                            </v-icon>
                            <span class="text-decoration-underline">Bitácora</span>
                          </v-btn>
                        </template>
                        <v-card class="modal-max-h">
                          <v-card-title class="text-blue-sky lighten-2">
                            <h6 class="text-h6 mt-8 mb-4">Bitácora</h6>
                            <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4" icon color="black">
                              <v-icon class="" @click="resetBitacora();dbitacora = false">
                                mdi-close
                              </v-icon>
                            </v-btn>
                            <div class="mb-4">
                              <span v-if="!isArchivado && !isProjectLeyRepublica" :class="{ active: isEditView,}" class="tab--modal" @click="isEditView = true">{{ bitacoraEditor.personName ? "Editar" : "Nueva" }} Bitácora</span>
                              <span :class="{ active: !isEditView}" class="tab--modal ml-4" @click="isEditView = false">Historial de Bitácoras</span>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="edit-container" v-if="isEditView">
                              <v-col cols="12" md="3" sm="6" class="mx-n3">
                                <v-menu v-model="dateBitacoraMenu" min-width="auto" offset-y
                                        :close-on-content-click="false"
                                        :nudge-right="40">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="solo-border" v-model="bitacoraEditor.date"
                                                  placeholder="Fecha"
                                                  flat single-line solo prepend-inner-icon="mdi-calendar"
                                                  v-bind="attrs"
                                                  v-on="on"></v-text-field>
                                  </template>
                                  <v-date-picker v-model="bitacoraEditor.date"
                                                @input="dateBitacoraMenu = false"></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-tiptap v-model="bitacoraEditor.comment" />
                            </div>
                            <div class="list-container" v-if="!isEditView || isArchivado || isProjectLeyRepublica">
                              <v-card class="mt-3 pa-6" v-for="(item, i) in currentProject.bitacoras" :key="i">
                                <div class="d-flex justify-space-between mb-3">
                                  <div class="text-body-2">
                                    <p class="mb-0 text-blue-sky font-weight-bold">{{ item.userName }}</p>
                                    <span class="text-gray-500"> {{ item.date }}</span>
                                  </div>
                                  <v-btn color="#3C77AF" text width="80" class="label-input" :disabled="isArchivado || isProjectLeyRepublica"
                                        @click="editBitacora(item, i)">
                                    <v-icon left color="#3C77AF">
                                      mdi-pencil-outline
                                    </v-icon>
                                    <span>
                                      Editar
                                    </span>
                                  </v-btn>
                                </div>
                                <v-tiptap v-model="item.comment" view />
                              </v-card>
                            </div>
                          </v-card-text>
                          <v-card-actions class="d-flex justify-end pb-5 px-6">
                            <v-btn :disabled="isArchivado || isProjectLeyRepublica"
                            v-if="bitacoraEditor.userName" color="#E83656" text width="160" class="label-input mr-auto"
                                  @click="removeBitacora()">
                              <v-icon left color="#E83656">
                                mdi-delete-outline
                              </v-icon>
                              Eliminar Bitácora
                            </v-btn>
                            <v-btn
                            color="#1867C0" outlined width="160" class="label-input" v-if="bitacoraEditor.userName"
                                  @click="resetBitacora();isEditView = false;">
                              Volver
                            </v-btn>
                            <v-btn color="#1867C0" outlined width="160" class="label-input"
                                  @click="isEditView = false;dbitacora = false" v-if="!bitacoraEditor.userName">
                              Salir
                            </v-btn>
                            <v-btn v-if="!isEditView && !isArchivado && !isProjectLeyRepublica" class="label-input" color="#1867C0" dark width="160"
                                  @click="newBitacora()">
                              Nueva Bitácora
                            </v-btn>
                            <v-btn v-if="isEditView" class="label-input" color="#1867C0" dark width="160"
                                  @click="addBitacora()">
                              Guardar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                    <div>
                      <v-chip v-if="initData.estados && currentProject.estado && currentProject.estado.key == '63372ea0-f3e4-11ed-a4e0-025631b309b0'"
                              class="mx-2"
                              color="teal"
                              text-color="white">
                        Activo
                      </v-chip>
                      <v-chip v-else-if="initData.estados && currentProject.estado && currentProject.estado.key == '634e488c-f3e4-11ed-a4e0-025631b309b0'"
                              class="mx-2"
                              color="gray"
                              text-color="gray">
                        Inactivo
                      </v-chip>
                      <v-chip v-else-if="initData.estados && currentProject.estado && currentProject.estado.key == '636535f0-f3e4-11ed-a4e0-025631b309b0'"
                              class="mx-2"
                              color="red"
                              text-color="white">
                        Archivado
                      </v-chip>
                      <v-chip v-else-if="initData.estados && currentProject.estado && currentProject.estado.key == '636535f0-f3e4-11ed-a4e0-025631b309b1'"
                              class="mx-2"
                              color="red"
                              text-color="white">
                        Terminado
                      </v-chip>
                    </div>
                  </div>
                  <section class="info-wrapper mt-8">
                    <h3 class="font-weight-bold text-h6 text-blue-sky">
                      Información General
                    </h3>
                    <v-row class="mt-5">
                      <v-col cols="12" sm="2">
                        <label class="label-input mb-2 d-inline-block">Número</label>
                        <v-text-field :disabled="isArchivado || isProjectLeyRepublica" :rules="[v => !!v || 'Este valor requerido']" required v-model.number="currentProject.expediente" class="solo-border" type="number" placeholder="Número" flat single-line solo></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <label class="label-input mb-2 d-inline-block">Criterio experto</label>
                        <v-text-field :disabled="isArchivado || isProjectLeyRepublica" v-model.number="currentProject.criterio" class="solo-border" type="number" placeholder="Criterio experto" flat single-line solo max="1" min="-1"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <label class="label-input mb-2 d-inline-block">Impacto</label>
                        <v-combobox class="solo-border" v-model="currentProject.impacto" required
                                            flat solo :disabled="isArchivado || isProjectLeyRepublica"
                                            :items="initData.impactos"
                                            item-value="key"
                                            item-text="value"
                                            return-object></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <label class="label-input mb-2 d-inline-block">Nombre
                          <v-icon left v-bind:title="currentProject.nombre">
                              mdi-information-variant-circle-outline
                          </v-icon>
                        </label>
                        <v-text-field :disabled="isArchivado || isProjectLeyRepublica" :rules="[v => !!v || 'Este valor requerido']" required v-bind:title="currentProject.nombre" v-model="currentProject.nombre" class="solo-border" placeholder="Nombre" flat single-line solo></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="my-0 mt-2">
                      <v-col cols="12" sm="4">
                        <label class="label-input mb-2 d-inline-block">Conocido como</label>
                        <v-text-field :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.alias" class="solo-border full-height-input" placeholder="Conocido como" flat solo></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <label class="label-input mb-2 d-inline-block">Proponente
                          <v-icon left v-bind:title="proponenteList(currentProject.proponente)">
                              mdi-information-variant-circle-outline
                          </v-icon>
                        </label>
                        <v-combobox class="solo-border" multiple required
                        :disabled="isArchivado || isProjectLeyRepublica"
                                    placeholder="Proponente"
                                    flat
                                    chips
                                    v-model="currentProject.proponente"
                                    :items="initData.proponentes"
                                    item-value="key"
                                    item-text="value"
                                    :rules="[v => !!v || 'Este valor requerido']"
                                    return-object
                                    solo>
                                    <template v-slot:selection="{ item, index }">
                                      <v-chip v-if="index < 4">
                                        <span>{{ item.value }}</span>
                                      </v-chip>
                                      <v-chip v-if="index === 4">
                                        <span> (+{{ currentProject.proponente.length - 4 }})</span>
                                      </v-chip>
                                    </template>
                                  </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row class="my-0">
                      <v-col cols="12" sm="4">
                        <label class="label-input mb-2 d-inline-block">Partido</label>
                        <v-combobox class="solo-border" v-model="currentProject.partido" required
                                            flat solo :disabled="isArchivado || isProjectLeyRepublica"
                                            :rules="[v => !!v || 'Este valor requerido']"
                                            :items="initData.partidos"
                                            item-value="key"
                                            item-text="value"
                                            return-object></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <label class="label-input mb-2 d-inline-block">Tema</label>
                        <v-text-field :disabled="isArchivado || isProjectLeyRepublica"
                         v-model="currentProject.tema" class="solo-border" placeholder="Tema" flat single-line solo></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <label class="label-input mb-2 d-inline-block">Cobertura</label>
                        <v-combobox :disabled="isArchivado || isProjectLeyRepublica"
                         class="solo-border" v-model="currentProject.cobertura"
                                            flat solo
                                            :items="initData.coberturas"
                                            item-value="key"
                                            item-text="value"
                                            return-object></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <label class="label-input mb-2 d-inline-block">Categoría</label>
                        <v-combobox :disabled="isArchivado || isProjectLeyRepublica"
                         v-model="currentProject.categorias"
                          class="solo-border" flat solo multiple
                          :items="initData.categorias"
                          item-value="key"
                          item-text="value"
                          return-object>
                        </v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <label class="label-input mb-2 d-inline-block">Afectación</label>
                        <v-combobox :disabled="isArchivado || isProjectLeyRepublica"
                         v-model="currentProject.afectacion"
                          class="solo-border" flat solo multiple
                          :items="initData.afectaciones"
                          item-value="key"
                          item-text="value"
                          return-object>
                        </v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <label class="label-input mb-2 d-inline-block">Sujetos Afectados:</label>
                        <v-combobox :disabled="isArchivado || isProjectLeyRepublica"
                         v-model="currentProject.afectaA"
                          class="solo-border" flat solo multiple
                          :items="initData.afectaA"
                          item-value="key"
                          item-text="value"
                          return-object>
                        </v-combobox>
                      </v-col>
                    </v-row>
                  </section>
                  <section class="info-wrapper mt-8">
                    <h3 class="font-weight-bold text-h6 text-blue-sky">
                      {{currentProject.type === 'tramiteOrdinario' ? 'Trámite Ordinario':'Trámite Reforma Constitucional'}}
                    </h3>
                    <v-row class="mt-5">
                      <v-col cols="6" sm="3">
                        <label class="label-input mb-2 d-inline-block">Fecha Presentación</label>
                        <v-menu :disabled="isArchivado || isProjectLeyRepublica"
                         v-model="currentProject.fechaPresentacionMenu" min-width="auto" offset-y
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field :disabled="isArchivado || isProjectLeyRepublica"
                               class="solo-border" v-model="currentProject.fechaPresentacionValue"
                                            placeholder="Fecha Presentación"
                                            required
                                            :rules="[v => !!v || 'Este valor requerido']"
                                            flat single-line solo prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="currentProject.fechaPresentacionValue"
                                            @input="currentProject.fechaPresentacionMenu = false"></v-date-picker>
                          </v-menu>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <label class="label-input mb-2 d-inline-block">Vencimiento Cuatrienal</label>
                        <v-menu :disabled="isArchivado || isProjectLeyRepublica"
                         v-model="currentProject.vencimientoCuatrienalMenu" min-width="auto" offset-y
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field :disabled="isArchivado || isProjectLeyRepublica" class="solo-border" v-model="currentProject.vencimientoCuatrienalValue"
                                            placeholder="Vencimiento Cuatrienal" required
                                            :rules="[v => !!v || 'Este valor requerido']"
                                            flat single-line solo prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="currentProject.vencimientoCuatrienalValue"
                                            @input="currentProject.vencimientoCuatrienalMenu = false"></v-date-picker>
                          </v-menu>
                      </v-col>
                      <v-col class="d-flex flex-wrap pt-4 checkbox-wrapper" cols="12" sm="6">
                        <v-checkbox :disabled="isArchivado || isProjectLeyRepublica"
                          class=""
                          v-model="currentProject.puestaDespacho"
                          label="Puesta a despacho"
                          color="#1867C0"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox :disabled="isArchivado || isProjectLeyRepublica"
                          v-model="currentProject.dispensaDeTramite"
                          @click="setDispensaTramite()"
                          label="Dispensa de Trámite"
                          color="#1867C0"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox :disabled="isArchivado || isProjectLeyRepublica"
                          v-model="currentProject.tieneMociones"
                          label="Tiene Mociones"
                          color="#1867C0"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox :disabled="isArchivado || isProjectLeyRepublica"
                          v-model="currentProject.viaRapida"
                          label="Vía Rápida"
                          color="#1867C0"
                          hide-details
                        >
                        <template v-slot:append v-if="currentProject.viaRapida">
                          <v-dialog v-model="dviaRapida" width="1000">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn min-width="20" class="pa-0 mt-n2" icon color="black" v-bind="attrs"
                                    v-on="on">
                                <v-icon color="#1867C0">
                                  mdi-text-box-edit-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title class="p-relative">
                                <h6 class="text-h6 mt-8">Vía Rápida Resumen</h6>
                                <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4" icon color="black">
                                  <v-icon color="#1867C0" class="" @click="dviaRapida = false">
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <v-tiptap v-model="currentProject.viaRapidaResumen" :view="isArchivado || isProjectLeyRepublica" />
                              </v-card-text>
                              <v-card-actions class="d-flex justify-end pb-8 px-6">
                                <v-btn color="#1867C0" outlined width="90" class="label-input"
                                      @click="dviaRapida = false">
                                  Salir
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                        </v-checkbox>
                        <v-checkbox :disabled="isArchivado || isProjectLeyRepublica || initData.isOrdinaria"
                          v-bind:class="{'cursor-disabled': initData.isOrdinaria }"
                          v-model="currentProject.convocado"
                          label="Convocado"
                          color="#1867C0"
                          hide-details
                          ></v-checkbox>
                        <v-checkbox
                          :disabled="!isAdmin || isArchivado"
                          v-model="currentProject.consultado"
                          color="#1867C0"
                          hide-details
                        >
                        <template v-slot:label>
                          <span>Consultado ABC {{currentProject.consultado && currentProject.consultados.length > 0 ? `(${currentProject.consultados.length})` : '' }}</span>
                        </template>
                        <template v-slot:append v-if="currentProject.consultado">
                          <v-dialog v-model="dconsultado" width="1200">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn min-width="20" class="pa-0 mt-n2" icon color="black" v-bind="attrs"
                                    v-on="on">
                                <v-icon color="#1867C0" class="">
                                  mdi-cloud-upload
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title class="p-relative">
                                <h6 class="text-h6 mt-8">Adjuntar Consultado</h6>
                                <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4" icon color="black">
                                  <v-icon color="#1867C0" class="" @click="dconsultado = false">
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <v-row class="mt-5 mb-4" v-for="(item, i) in currentProject.consultados" :key="i">
                                  <v-col cols="9" sm="3">
                                    <label class="label-input mb-2 d-inline-block">Oficio ABC</label>
                                    <v-text-field :disabled="isArchivado || isProjectLeyRepublica" v-model="item.codigoABC" class="solo-border" placeholder="Código ABC" flat single-line solo></v-text-field>
                                  </v-col>
                                  <v-col cols="9" sm="3">
                                    <label class="label-input mb-2 d-inline-block">Fecha de Notificación</label>
                                    <v-menu :disabled="isArchivado || isProjectLeyRepublica" v-model="item.fechaNotificacionMenu" min-width="auto" offset-y
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field  :disabled="isArchivado || isProjectLeyRepublica" class="solo-border" v-model="item.fechaNotificacion"
                                                        placeholder="Fecha de Notificación"
                                                        flat single-line solo prepend-inner-icon="mdi-calendar"
                                                        v-bind="attrs"
                                                        v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="item.fechaNotificacion"
                                                        @input="item.fechaNotificacionMenu = false"></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col cols="9" sm="3">
                                    <label class="label-input mb-2 d-inline-block">Fecha de Respuesta</label>
                                    <v-menu :disabled="isArchivado || isProjectLeyRepublica" v-model="item.fechaRespuestaMenu" min-width="auto" offset-y
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field :disabled="isArchivado || isProjectLeyRepublica" class="solo-border" v-model="item.fechaRespuesta"
                                                        placeholder="Fecha de Respuesta"
                                                        flat single-line solo prepend-inner-icon="mdi-calendar"
                                                        v-bind="attrs"
                                                        v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="item.fechaRespuesta"
                                                        @input="item.fechaRespuestaMenu = false"></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col cols="3"                                                                                                               >
                                    <label class="label-input mb-2 d-inline-block"></label>
                                    <div class="action-wrapper mt-3 d-flex" v-if="isAdmin">
                                      <v-dialog v-if="!(item.document && item.document.url)" v-model="dDocument3" width="700">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn :disabled="isArchivado || isProjectLeyRepublica" min-width="20" class="pa-0" icon color="black"
                                            v-bind="attrs"
                                            v-on="on">
                                            <v-icon color="#1867C0" class="">
                                              mdi-upload-outline
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                        <v-card>
                                          <v-card-title class="p-relative">
                                            <h6 class="text-h6 mt-8">Subir Documento</h6>
                                            <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4" icon color="black">
                                              <v-icon color="#1867C0" class="" @click="dDocument3 = false">
                                                mdi-close
                                              </v-icon>
                                            </v-btn>
                                          </v-card-title>
                                          <v-card-text>
                                            <v-row>
                                              <v-col cols="12" sm="6">
                                                <v-menu
                                                    ref="menuDocDate2"
                                                    v-model="menuDocDate2"
                                                    :return-value.sync="menuDocDate2"
                                                    transition="scale-transition"
                                                    offset-y
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    min-width="auto"
                                                  >
                                                    <template v-slot:activator="{ on, attrs }">
                                                      <v-text-field
                                                          class="text-field-date-doc"
                                                          v-model="docDate"
                                                          prepend-icon="mdi-calendar"
                                                          flat
                                                          v-bind="attrs"
                                                          v-on="on"
                                                        >
                                                      </v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                      v-model="docDate"
                                                      no-title
                                                      scrollable
                                                    >
                                                      <v-spacer></v-spacer>
                                                      <v-btn
                                                        text
                                                        color="primary"
                                                        @click="menuDocDate2 = false"
                                                      >
                                                        Cancel
                                                      </v-btn>
                                                      <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.menuDocDate2.save(date)"
                                                      >
                                                        OK
                                                      </v-btn>
                                                    </v-date-picker>
                                                  </v-menu>
                                              </v-col>
                                              <v-col cols="12" sm="6">
                                                <v-btn :disabled="isArchivado || isProjectLeyRepublica"
                                                  class="label-input w-full"
                                                  color="#1867C0"
                                                    :loading="isSelecting"
                                                    @click="handleFileImport({
                                                      path: 'currentProject.consultados',
                                                      index: i
                                                    })">
                                                    <v-icon left class="white--text">
                                                      mdi-upload-outline
                                                    </v-icon>
                                                    <span class="white--text font-weight-bold d-none d-sm-flex">Selecionar Documento</span>
                                                  </v-btn>
                                              </v-col>
                                            </v-row>
                                          </v-card-text>
                                          <v-card-actions class="d-flex justify-end pb-8 px-6">
                                            <v-btn color="#1867C0" outlined width="90" class="label-input"
                                                  @click="dDocument3 = false">
                                              Salir
                                            </v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </v-dialog>
                                      <v-btn min-width="20" icon color="black no-text-transform" v-if="item.document && item.document.url" target="_blank"
                                      :href="isOfficeDoc(item.document.text) ? `https://view.officeapps.live.com/op/embed.aspx?src=${item.document.url}` : item.document.url">
                                        <v-icon left color="#1867C0">
                                          mdi-download-outline
                                        </v-icon>
                                      </v-btn>
                                      <v-btn :disabled="isArchivado || isProjectLeyRepublica" min-width="20" class="ml-2 pa-0" icon color="black">
                                        <v-icon color="#1867C0" class="" :loading="isSelecting" @click="removeConsultado(i)">
                                          mdi-delete-outline
                                        </v-icon>
                                      </v-btn>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-btn :disabled="isArchivado || isProjectLeyRepublica" text class="label-input pl-0" color="#1867C0" @click="agregarConsultado()">
                                  <v-icon left>
                                    mdi-plus
                                  </v-icon>
                                  <span class="">Agregar nueva consulta</span>
                                </v-btn>
                              </v-card-text>
                              <v-card-actions class="d-flex justify-end pb-8 px-6">
                                <v-btn color="#1867C0" outlined width="90" class="label-input"
                                      @click="dconsultado = false">
                                  Salir
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <label class="label-input mb-2 d-inline-block">Fase</label>
                        <v-combobox :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.fase" required
                            :rules="[v => !!v || 'Este valor requerido']"
                            class="solo-border" flat solo
                            :items="getFases"
                            item-value="key"
                            item-text="value"
                            return-object>
                        </v-combobox>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <template v-if="currentProject.fase && (currentProject.fase.value  == 'Ley de la República' || currentProject.fase.value == 'Poder Ejecutivo (Pendiente de Sanción)')">
                            <v-dialog v-if="isAdmin" v-model="dDocument1" width="700">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="isArchivado && !isProjectLeyRepublica" class="label-input w-full mt-8" dark color="#1867C0"
                                  :loading="isSelecting"
                                  v-bind="attrs"
                                  v-on="on">
                                    <v-icon left class="white--text">
                                      mdi-upload-outline
                                    </v-icon>
                                    <span class="white--text font-weight-bold">Documento Vigente</span>
                                  </v-btn>
                              </template>
                              <v-card>
                                <v-card-title class="p-relative">
                                  <h6 class="text-h6 mt-8">Subir Documento</h6>
                                  <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4" icon color="black">
                                    <v-icon color="#1867C0" class="" @click="dDocument1 = false">
                                      mdi-close
                                    </v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-card-text>
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <v-menu
                                          ref="menuDocDate3"
                                          v-model="menuDocDate3"
                                          :return-value.sync="menuDocDate3"
                                          transition="scale-transition"
                                          offset-y
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          min-width="auto"
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                class="text-field-date-doc"
                                                v-model="docDate"
                                                prepend-icon="mdi-calendar"
                                                flat
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                            </v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="docDate"
                                            no-title
                                            scrollable
                                          >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                              text
                                              color="primary"
                                              @click="menuDocDate3 = false"
                                            >
                                              Cancel
                                            </v-btn>
                                            <v-btn
                                              text
                                              color="primary"
                                              @click="$refs.menuDocDate3.save(date)"
                                            >
                                              OK
                                            </v-btn>
                                          </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <v-btn :disabled="isArchivado || isProjectLeyRepublica"
                                        class="label-input w-full"
                                        color="#1867C0"
                                          :loading="isSelecting"
                                          @click="handleFileImport({
                                            path: 'currentProject'
                                          })">
                                          <v-icon left class="white--text">
                                            mdi-upload-outline
                                          </v-icon>
                                          <span class="white--text font-weight-bold d-none d-sm-flex">Selecionar Documento</span>
                                        </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end pb-8 px-6">
                                  <v-btn color="#1867C0" outlined width="90" class="label-input"
                                        @click="dDocument1 = false">
                                    Salir
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                        </template>
                        <template v-if="currentProject.fase && currentProject.fase.value == 'Comisión'">
                          <label class="label-input mb-2 d-inline-block">Subfase</label>
                          <v-combobox :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.subfase"
                            :items="initData.subfases"
                            class="solo-border" flat solo
                            item-value="key"
                            item-text="value"
                            return-object></v-combobox>
                        </template>
                        <template v-if="currentProject.fase && currentProject.fase.value == 'Plenario'">
                          <label class="label-input mb-2 d-inline-block">Subfase</label>
                          <v-combobox :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.plenarioSubfase"
                            class="solo-border" flat solo
                            :items="initData.plenarioSubfases"
                            item-value="key"
                            item-text="value"
                            return-object></v-combobox>
                        </template>
                        <template v-if="currentProject.fase.value == 'Comisiones Plenas'">
                          <label class="label-input mb-2 d-inline-block">Plenas</label>
                          <v-combobox :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.plena" flat solo
                              :items="initData.plenas"
                              item-value="key"
                              item-text="value"
                              class="solo-border"
                              return-object>
                          </v-combobox>
                      </template>
                      </v-col>
                      <template v-if="(currentProject.plenarioSubfase && currentProject.fase) && currentProject.fase.value == 'Plenario' && currentProject.plenarioSubfase.value == 'Primer Debate'">
                        <v-col cols="12" sm="3">
                          <label class="label-input mb-2 d-inline-block">Subfase 2</label>
                          <v-combobox :disabled="isArchivado || isProjectLeyRepublica"
                            v-model="currentProject.subSubFase"
                            class="solo-border" flat solo
                            :items="initData.subSubFases"
                            item-value="key"
                            item-text="value"
                            return-object>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <label class="label-input mb-2 d-inline-block">Reporte</label>
                          <v-combobox :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.monitoreo"
                              class="solo-border" flat solo
                              :items="initData.monitoreos"
                              item-value="key"
                              item-text="value"
                              return-object>
                          </v-combobox>
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col cols="12" sm="6">
                          <label class="label-input mb-2 d-inline-block">Reporte</label>
                          <v-combobox :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.monitoreo"
                              class="solo-border" flat solo
                              :items="initData.monitoreos"
                              item-value="key"
                              item-text="value"
                              return-object>
                          </v-combobox>
                        </v-col>
                      </template>
                      <template v-if="currentProject.fase && currentProject.fase.value == 'Plenario'">
                          <v-col cols="12" sm="6">
                            <label class="label-input mb-2 d-inline-block">Comisión</label>
                            <v-combobox :disabled="true" v-model="currentProject.comision"
                                        :items="initData.comisiones"
                                        class="solo-border" flat solo
                                        item-value="key"
                                        item-text="value"
                                        return-object
                                        >
                              <!-- <template v-slot:selection="data">
                                <v-chip v-bind="data.attrs"
                                        :input-value="data.selected"
                                        @click="data.select">
                                  {{ data.item.value }}
                                </v-chip>
                              </template> -->
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content class="ml-6">
                                    <v-list-item-title v-html="data.item.value"></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-combobox>
                          </v-col>
                          <v-col cols="12" sm="3">
                          <label class="label-input mb-2 d-inline-block">Orden del día</label>
                          <v-text-field type="number" flat solo :disabled="isArchivado || isProjectLeyRepublica"
                                        class="solo-border"
                                        min="1"
                                        v-model="currentProject.ordenSubcomision">
                          </v-text-field>
                        </v-col>
                        </template>
                      <!-- <template v-if="currentProject.fase && currentProject.fase.value == 'Plenario' && this.currentProject.type === 'reformaConstitucional'">
                        <v-col cols="12" sm="6">
                          <label class="label-input mb-2 d-inline-block">Legislatura</label>
                          <v-combobox :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.legislatura"
                                      :items="initData.legislaturas"
                                      class="solo-border" flat solo
                                      item-value="key"
                                      item-text="value"
                                      return-object>
                          </v-combobox>
                        </v-col>
                      </template> -->
                      <template v-if="currentProject.fase && currentProject.fase.value == 'Plenario' && currentProject.plenarioSubfase && currentProject.plenarioSubfase.value == 'Primer Debate'">
                        <template v-if=" (currentProject.subSubFase) && (currentProject.subSubFase.value == 'Mociones art. 137 – 1 Día' || currentProject.subSubFase.value == 'Mociones art. 137 – 2 Día' || currentProject.subSubFase.value == 'Mociones art. 138 – reiteración')" >
                          <v-col cols="12" sm="3">
                            <v-checkbox :disabled="isArchivado || isProjectLeyRepublica"
                              v-bind:class="{'mt-8': currentProject.subSubFase.value !== 'Mociones art. 138 – reiteración'}"
                              v-model="currentProject.tieneMociones"
                              label="Tiene mociones presentadas"
                              color="#1867C0"
                            ></v-checkbox>
                          </v-col>
                        </template>
                      </template>
                      <!-- <template v-if="currentProject.fase && (currentProject.fase.value  == 'Ley de la República' || currentProject.fase.value == 'Poder Ejecutivo (Pendiente de Sanción)')">
                        <v-file-input
                          prepend-icon="upload-outline"
                          show-size flat solo label="Subir documento"></v-file-input>
                      </template> -->
                      <template v-if="currentProject.fase.value == 'Comisión' && currentProject.subfase.value == 'Trámite'">
                        <v-col cols="12" sm="6">
                          <label class="label-input mb-2 d-inline-block">Comisión</label>
                          <v-combobox v-model="currentProject.comision" flat solo :disabled="isArchivado || isProjectLeyRepublica"
                                      class="solo-border"
                                      :items="initData.comisiones"
                                      item-value="key"
                                      item-text="value"
                                      return-object >
                            <template v-slot:selection="data">
                              {{ data.item.value }}
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content class="ml-6">
                                  <v-list-item-title v-html="data.item.value"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <label class="label-input mb-2 d-inline-block">Orden del día</label>
                          <v-text-field type="number" flat solo :disabled="isArchivado || isProjectLeyRepublica"
                                        class="solo-border"
                                        min="1"
                                        v-model="currentProject.ordenSubcomision">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-menu :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.plazoDictaminarSubcomisionMenu" min-width="auto" offset-y
                                  :close-on-content-click="false"
                                  transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <label class="label-input mb-2 d-inline-block">Plazo Para Dictaminar</label>
                              <v-text-field :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.plazoDictaminarSubcomision"
                                            prepend-inner-icon="mdi-calendar" flat solo
                                            class="solo-border"
                                            v-bind="attrs"
                                            v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="currentProject.plazoDictaminarSubcomision"
                                          @input="currentProject.plazoDictaminarSubcomisionMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                      </template>
                      <template v-if="currentProject.fase.value == 'Comisión' && currentProject.subfase.value == 'Subcomisión'">
                        <v-col cols="12" sm="6">
                          <label class="label-input mb-2 d-inline-block">Comisión</label>
                          <v-combobox :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.comision" flat solo
                                      class="solo-border" chips
                                      :items="initData.comisiones"
                                      item-value="key"
                                      item-text="value"
                                      return-object >
                            <template v-slot:selection="data">
                              {{ data.item.value }}
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content class="ml-6">
                                  <v-list-item-title v-html="data.item.value"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="6" md="3">
                          <label class="label-input mb-2 d-inline-block">Orden del dia</label>
                          <v-text-field type="number" flat solo :disabled="isArchivado || isProjectLeyRepublica"
                                        class="solo-border"
                                        min="1"
                                        v-model="currentProject.ordenSubcomision"></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-menu :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.plazoDictaminarSubcomisionMenu" min-width="auto" offset-y
                                  :close-on-content-click="false"
                                  transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <label class="label-input mb-2 d-inline-block">Plazo para Dictaminar</label>
                              <v-text-field :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.plazoDictaminarSubcomision"
                                            prepend-inner-icon="mdi-calendar" flat solo
                                            class="solo-border"
                                            v-bind="attrs"
                                            v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="currentProject.plazoDictaminarSubcomision"
                                          @input="currentProject.plazoDictaminarSubcomisionMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6" md="9">
                          <label class="label-input mb-2 d-inline-block">SubComision
                            <v-icon left v-bind:title="proponenteList(currentProject.proponenteSubcomision)">
                              mdi-information-variant-circle-outline
                            </v-icon>
                          </label>
                          <v-combobox :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.proponenteSubcomision"
                                      :items="subComisionDiputados" chips
                                      multiple
                                      item-value="key"
                                      item-text="value"
                                      class="solo-border" flat solo>
                                      <template v-slot:selection="{ item, index }">
                                      <v-chip v-if="index < 4">
                                        <span>{{ item.value }}</span>
                                      </v-chip>
                                      <v-chip v-if="index === 4">
                                        <span> (+{{ currentProject.proponenteSubcomision.length - 4 }})</span>
                                      </v-chip>
                                    </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-menu :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.plazoSubcomisionMenu" min-width="auto" offset-y
                                  :close-on-content-click="false"
                                  transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <label class="label-input mb-2 d-inline-block">Plazo de Subcomisión</label>
                              <v-text-field :disabled="isArchivado || isProjectLeyRepublica" v-model="currentProject.plazoSubcomision"
                                            prepend-inner-icon="mdi-calendar" flat solo
                                            class="solo-border"
                                            v-bind="attrs"
                                            v-on="on"></v-text-field>
                            </template>
                            <v-date-picker day-format="" v-model="currentProject.plazoSubcomision"
                                          @input="currentProject.plazoSubcomisionMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                      </template>
                    </v-row>
                  </section>
                  <v-divider class="my-8"></v-divider>
                  <section class="info-wrapper">
                    <div class="d-flex justify-space-between align-center">
                      <h3 class="font-weight-bold text-h6 text-blue-sky">
                        Documentos
                      </h3>
                      <v-dialog v-if="isAdmin" v-model="dDocument2" width="700">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="isArchivado || isProjectLeyRepublica"
                              v-bind="attrs"
                              v-on="on"
                              class="label-input"
                              color="#1867C0"
                                style="max-width: 200px;"
                                :loading="isSelecting">
                                <v-icon left class="white--text">
                                  mdi-upload-outline
                                </v-icon>
                                <span class="white--text font-weight-bold d-none d-sm-flex">Subir Documento</span>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title class="p-relative">
                              <h6 class="text-h6 mt-8">Subir Documento</h6>
                              <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4" icon color="black">
                                <v-icon color="#1867C0" class="" @click="dDocument2 = false">
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" sm="6">
                                  <v-menu
                                      ref="menuDocDate4"
                                      v-model="menuDocDate4"
                                      :return-value.sync="menuDocDate4"
                                      transition="scale-transition"
                                      offset-y
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="solo-border" v-model="docDate"
                                        placeholder="Vencimiento Cuatrienal" required
                                        :rules="[v => !!v || 'Este valor requerido']"
                                        flat single-line solo prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="docDate"
                                        no-title
                                        scrollable
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          text
                                          color="primary"
                                          @click="menuDocDate4 = false"
                                        >
                                          Cancel
                                        </v-btn>
                                        <v-btn
                                          text
                                          color="primary"
                                          @click="$refs.menuDocDate4.save(date)"
                                        >
                                          OK
                                        </v-btn>
                                      </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-btn :disabled="isArchivado || isProjectLeyRepublica"
                                    class="label-input w-full"
                                    color="#1867C0"
                                      :loading="isSelecting"
                                      @click="handleFileImport('dddddd')">
                                      <v-icon left class="white--text">
                                        mdi-upload-outline
                                      </v-icon>
                                      <span class="white--text font-weight-bold d-none d-sm-flex">Selecionar Documento</span>
                                    </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end pb-8 px-6">
                              <v-btn color="#1867C0" outlined width="90" class="label-input"
                                    @click="dDocument2 = false">
                                Salir
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                      </v-dialog>
                    </div>
                    <v-text-field
                      v-model="searchDocTable"
                      append-icon="mdi-magnify"
                      label="Filtar"
                      class="px-0"
                      clearable
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-data-table
                        class="text-body-1"
                        :header-props="headerDocTableProps"
                        locale="es-ES"
                        :headers="headersDoc"
                        :items="currentProject.documentos"
                        :search="searchDocTable"
                        :footer-props="{
                            'items-per-page-text':'Documentos por pagina',
                            'items-per-page-options': [
                              20,
                              40,
                              60,
                              -1
                            ]
                        }"
                      >
                      <template v-slot:item="row">
                          <tr>
                            <td >
                              <v-icon color="#1867C0" v-text="getDocIcon(row.item.text)"></v-icon>
                            </td>
                            <td class="text-black cursor-pointer hover-underline">
                              <a class="text-black" target="_blank" :href="isOfficeDoc(row.item.text) ? `https://view.officeapps.live.com/op/embed.aspx?src=${row.item.url}` : row.item.url">
                                <span class="">
                                  {{ row.item.text }}
                                </span>
                              </a>
                            </td>
                            <td class="text-black">
                                <span class="">
                                  {{ row.item.fecha }}
                                </span>
                            </td>
                            <td class="d-flex justify-end align-center">
                              <v-btn icon>
                                  <a @click="openDialog(
                                              isOfficeDoc(row.item.text)
                                                  ? `https://view.officeapps.live.com/op/embed.aspx?src=${row.item.url}`
                                                  : row.item.url
                                          )">
                                  <v-icon color="#1867C0" >
                                    mdi-eye
                                  </v-icon>
                                </a>
                              </v-btn>
                              <v-btn icon>
                                  <a class="" v-bind:href="row.item.url" download>
                                    <v-icon color="#1867C0" >
                                    mdi-cloud-download
                                    </v-icon>
                                  </a>
                              </v-btn>
                            </td>
                          </tr>
                      </template>
                    </v-data-table>
                  </section>
                </v-col>
                <input ref="uploader" class="d-none" type="file" @change="onFileChanged">
              </v-row>
              <v-dialog v-model="viewDocDialog" width="1200">
                  <v-card>
                      <v-card-title class="headline">Documento</v-card-title>
                      <v-card-text style="height: 70vh">
                          <iframe :src="urlDocDialog" width="100%" height="100%"></iframe>
                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="viewDocDialog = false">Cerrar</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
            </v-container>
            </div>
            <div class="border-top">
              <v-container>
                <div class="d-flex justify-end">
                  <v-btn v-if="!isArchivado" elevation="2" class="label-input" color="#E83656" @click="deleteProject()">
                    <v-icon left class="white--text">
                      mdi-archive-outline
                    </v-icon>
                    <span class="white--text font-weight-bold">Archivar Expediente</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn outlined text class="label-input" @click="close()">
                    <span class="font-weight-bold">Volver busqueda</span>
                  </v-btn>
                  <v-btn class="ml-2 label-input"
                    v-if="isAdmin"
                    elevation="2"
                    :loading="savingProject"
                    color="#1867C0" @click="save()" :disabled="!isFormValid || savingProject || isArchivado">
                    <span class="white--text font-weight-bold">Guardar</span>
                  </v-btn>
                </div>
              </v-container>
            </div>
          </v-form>
        </v-scale-transition>
      </v-main>
    </div>
</template>
<script>
  import { format } from 'date-fns'

  export default {
    name: 'ProjectView',
    components: {
    },
    watch: {
      saveSuccessAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveSuccessAlert=false},3000)
        }
      },
      saveErrorAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveErrorAlert=false},6000)
        }
      }
    },
    data: () => ({
      urlDocDialog: '',
      viewDocDialog: false,
      dDocument1: false,
      dDocument2: false,
      dDocument3: false,
      dDocument4: false,
      dDocument5: false,
      menuDocDate1: false,
      menuDocDate2: false,
      menuDocDate3: false,
      menuDocDate4: false,
      menuDocDate5: false,
      docDate: null,
      searchDocTable: '',
      headerDocTableProps: {
        sortByText: "Filtrar por"
      },
      headersDoc: [
        {
          text: 'Tipo',
          align: 'start',
          sortable: false
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'text',
        },
        {
          text: 'Fecha del Documento',
          align: 'start',
          sortable: true,
          value: 'fecha',
        },
        {
          text: '',
          align: 'end',
          sortable: false
        }
      ],
      loadingProject: false,
      savingProject: false,
      isProjectLeyRepublica: false,
      updatingDocument: {
        section:'',
        index: null
      },
      saveSuccessAlert: false,
      saveErrorAlert: false,
      isFormValid: false,
      isSelecting: false,
      // Bitacora:
      bitacoraCurrentIndex: null,
      dateBitacoraMenu: false,
      isEditView: false,
      bitacoraEditor: {
        userName: "",
        text: "",
        date: null
      },
      currentDoc:{
        url: ''
      },
      currentProject: {
        id: "00000000-0000-0000-0000-000000000000",
        expediente: 0,
        alias: "",
        nombre: "",
        estado: null,
        textoVigente: {},
        archivado: false,
        proponente: null,
        partido: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        tema: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        cobertura: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        categorias: [{
          key: "00000000-0000-0000-0000-000000000000",
          value: ""
        }],
        afectacion: [{
          key: "00000000-0000-0000-0000-000000000000",
          value: ""
        }],
        afectaA: [{
          key: "00000000-0000-0000-0000-000000000000",
          value: ""
        }],
        fechaPresentacionMenu: false,
        fechaPresentacionValue: null,
        vencimientoCuatrienalMenu: false,
        vencimientoCuatrienalValue: null,
        monitoreo: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        fase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        subfase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        subSubFase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        plenarioSubfase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        legislatura: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        plena: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        comision: {
          key: null,
          value: null,
          group: null,
          header: null,
          divider: null
        },
        comisionTramite: {
          key: null,
          value: null,
          group: null,
          header: null,
          divider: null
        },
        comisionSubComision: {
          key: null,
          value: null,
          group: null,
          header: null,
          divider: null
        },
        ordenTramite: 0,
        puestaDespacho: false,
        convocado: false,
        consultado: false,
        viaRapida: false,
        viaRapidaResumen: '',
        dispensaDeTramite: false,
        tieneMociones: false,
        plazoDictaminarTramiteMenu: false,
        plazoDictaminarTramite: null,
        plazoDictaminarSubcomisionMenu: false,
        plazoDictaminarSubcomision: null,
        ordenSubcomision: 0,
        proponenteSubcomision: [

        ],
        plazoSubcomision: null,
        estados: [

        ],
        consultados: [

        ],
        documentos: [

        ],
        bitacora: [

        ],
        resumen: ""
      },
      initData: {},
      dresumen: false,
      dbitacora: false,
      dconsultado: false,
      dviaRapida: false,
      valid: false,
      bitacora: [{
        personName: "",
        text: "",
        date: null
      }],
      resumen: "",
    }),
    created() {
      
      this.loadInitData();

    },
    mounted() {
      this.loadProject();
      this.docDate = format(new Date(), 'yyyy-MM-dd');
    },
    computed: {
      isAdmin() {
        if (localStorage.getItem('userRole') && localStorage.getItem('userRole') === 'cfb41f8e-9aa3-4d18-8fe6-7198fa2a4cce') {
          return true;
        }
        return false;
      },
      isArchivado() {
        if (localStorage.getItem('userRole') && localStorage.getItem('userRole') !== 'cfb41f8e-9aa3-4d18-8fe6-7198fa2a4cce') {
          return true;
        }
        if(this.currentProject && this.currentProject.estado && this.initData && this.initData.estados){
          return (this.currentProject.estado.key === '636535f0-f3e4-11ed-a4e0-025631b309b1' ||
          this.currentProject.estado.key === '636535f0-f3e4-11ed-a4e0-025631b309b0')
           
        }
        return false;
      },
      subComisionDiputados() {
        if( this.initData.comisiones) {
            const comision = this.initData.comisiones.find(
            comision => comision.key == this.currentProject.comision.key
          );
          return comision ? comision.children : [];
        }
        return [];
      },
      getFases() {
        if( this.initData.fases) {
            const fases = this.initData.fases.filter(fase => {
              if(
                this.currentProject.type === 'reformaConstitucional'
                && (fase.value === 'Comisión'
                || fase.value === 'Comisiones Plenas'
                || fase.value === 'Poder Ejecutivo (Pendiente de Sanción)')
                ){
                  return false;
              } else {
                return true;
              }
            })
            return fases;
        }
        return [];
      }
    },
    methods: {
      openDialog(url) {
            this.urlDocDialog = url;
            this.viewDocDialog = true;
        },
      toJSONLocal(date) {
        const local = new Date(date);
        new Date().setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
      },
      proponenteList(proponenteList) {
        if(proponenteList && proponenteList.length){
          return proponenteList.map((obj) => obj.value).join(', \n')
        }
        return '';
        
      },
      setDispensaTramite() {
        if(this.currentProject.dispensaDeTramite){
          this.currentProject.fase = this.initData.fases.find((item) => item.value === 'Plenario');
          this.currentProject.plenarioSubfase = this.initData.plenarioSubfases.find((item) => item.value === 'Primer Debate');
          this.currentProject.subSubFase = this.initData.subSubFases.find((item) => item.value === 'Mociones fondo art. 177 - Dispensa Trámite');
        }
      },
      stateDocumentUpdate(path, value, index) {
          let schema = this;
          let pList = path.split('.');
          let len = pList.length;
          for(let i = 0; i < len-1; i++) {
              let elem = pList[i];
              if( !schema[elem] ) schema[elem] = {}
              schema = schema[elem];
          }
          if(index === 0 || index > 0){
            schema[pList[len-1]][index].document = value;
          } else {
            schema[pList[len-1]].document = value;
          }
          this.updatingDocument = {};
      },
      getDocIcon(filename) {
        if(filename && filename.indexOf('.' > 0)){
          const extFile = filename.split('.').pop().toLowerCase();
          switch(extFile){
            case 'pdf':
            return 'mdi-file-pdf-box';
            case 'doc':
            case 'docx':
            return 'mdi-file-word';
            case 'xls':
            case 'xlsx':
              return 'mdi-microsoft-excel';
            default:
              return 'mdi-file-document-outline';
          }
        }
      },
      isOfficeDoc(filename){
        if(filename && filename.indexOf('.' > 0)){
          const extFile = filename.split('.').pop().toLowerCase();
          switch(extFile){
            case 'doc':
            case 'docx':
            case 'xls':
            case 'xlsx':
              return true;
            default:
              return false;
          }
        }
        return false;
      },
      getCurrentDocument(){
        const doct = this.currentProject.documentos.find(document => document.isCurrentDocument);
        if( doct ) {
          this.currentDoc = doct;
        }
        return doct;
      },
      editBitacora(item, index) {
        this.bitacoraCurrentIndex = index;
        this.bitacoraEditor = {...item};
        this.isEditView = true;
        this.currentProject.bitacoras[index] = this.bitacoraEditor;
      },
      resetBitacora() {
        this.isEditView = false;
        this.bitacoraEditor = {
          userName: null,
          text: "",
          date: format(new Date(),'yyyy-MM-dd')
        };
      },
      newBitacora() {
        this.isEditView = true;
        this.bitacoraEditor = {
          userName: null,
          text: "",
          date: format(new Date(),'yyyy-MM-dd')
        };
      },
      addBitacora() {
        const isEdit = this.bitacoraEditor.userName ? true : false;
        this.bitacoraEditor.userName = localStorage.getItem('userFullName');
        if(!isEdit) {
          this.currentProject.bitacoras.unshift(
            {...this.bitacoraEditor}
          );
        } else {

        }

        this.resetBitacora();
      },
      removeBitacora() {
        this.currentProject.bitacoras = this.currentProject.bitacoras.filter( (item, i) => i !== this.bitacoraCurrentIndex);
        this.resetBitacora();
      },
      removeConsultado(index) {
        this.currentProject.consultados = this.currentProject.consultados.filter( (item, i) => i !== index);
      },
      agregarConsultado() {
        this.currentProject.consultados.push({
          document: {}
        })
      },
      handleFileImport(pathAndIndex) {
        this.updatingDocument = pathAndIndex;
        this.isSelecting = true;
        // After obtaining the focus when closing the FilePicker, return the button state to normal
        window.addEventListener('focus', () => {
          this.isSelecting = false;
        }, { once: true });

        // Trigger click on the FileInput
        if (this.$refs.uploader instanceof HTMLElement) {
          this.$refs.uploader.click();
        }
      },
      save() {
        this.saveSuccessAlert = false;
        this.savingProject = true;

        if (this.currentProject.consultados.length > 0) {
          this.currentProject.consultados = this.currentProject.consultados.map(consultado => {
            if (consultado.document && consultado.document.id){
              return consultado;
            } else {
              delete consultado.document;
            }
            return consultado;
          })
        }
        if (this.$refs.formEdit.validate()) {
          if (this.currentProject.id === '00000000-0000-0000-0000-000000000000') {
            this.currentProject.id = this.$route.params.id;
          }
          fetch('/api/Project', {
          method: 'POST',
          body: JSON.stringify(this.currentProject),
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }).then(response => {
          if(response.status === 200){
            this.saveSuccessAlert = true;
          } else {
            this.saveErrorAlert= true;
          }
          return response;
        })
          .then(response => response.json())
          .then(data => {
              this.currentProject = data;
              if((data.fase && this.initData.fases) && data.fase.key == this.initData.fases[5].key) {
                this.isProjectLeyRepublica = true;
              }
              this.isProjectLeyRepublica
              this.savingProject = false;
          })
          .catch(error => {
            this.savingProject = false;
          });
        }
      },
      loadInitData() {
        var self = this;

        fetch('/api/Project/InitData', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            if (response.status === 401) {
              localStorage.clear('token');
              this.$router.push('/login');
            }
            return response.json()})
          .then(data => {
            self.initData = data;
            if((this.currentProject.fase && this.initData.fases) && this.currentProject.fase.key == this.initData.fases[5].key) {
                this.isProjectLeyRepublica = true;
              }
          })
          .catch(error => console.error(error));
      },
      loadProject() {
        var self = this;
        this.loadingProject = true;
        fetch('/api/Project/' + this.$route.params.id, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            self.currentProject = data;
            if (this.$route.params.type) {
              self.currentProject.type = this.$route.params.type;
            }
            if((data.fase && self.initData.fases) && data.fase.key == self.initData.fases[5].key) {
              this.isProjectLeyRepublica = true;
            }
            this.loadingProject = false;
            // self.currentProject.documentos[0].isCurrentDocument = true;
          })
          .catch(error => {console.error(error)});
      },
      deleteProject() {
        this.currentProject.estado = this.initData.estados[2];
        this.save();
      },
      close() {
        this.$router.push({ name: 'SearchView' })
      },
      onFileChanged(e) {
        this.selectedFile = e.target.files[0];
        // upload file to store.
        var result = this.uploadFile();
      },
      uploadFile() {
        if (this.selectedFile) {

          const formData = new FormData();
          formData.append('Fecha', this.docDate);
          formData.append('File', this.selectedFile);
          if(this.currentProject.id && this.currentProject.id === '00000000-0000-0000-0000-000000000000') {
            formData.append('ProjectId', this.$route.params.id);
          } else {
            formData.append('ProjectId', this.currentProject.id);
          }
          fetch('/api/Project/UploadFile', {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              //'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => response.json())
            .then(data => {
              // Handle the response as needed
              this.dDocument1= false;
              this.dDocument2= false;
              if(this.updatingDocument.path) {
                this.stateDocumentUpdate(this.updatingDocument.path, data, this.updatingDocument.index)
              }
              this.currentProject.documentos = [...this.currentProject.documentos, data];
              return data;
            })
            .catch(error => {
              // Handle the error
              console.error(error);
            });
        }
      },
    }
};
</script>
